import Cookies from 'js-cookie'
export function getToken(e='access_token') {
    return Cookies.get(e)
}

export function setToken(k,y,num=7) {
    return Cookies.set(k, y,{ expires: num,domain:'hd199.com'})
}

export function removeToken(e='access_token') {
    return Cookies.remove(e)
}
export function setLoc(key, data) {
    return localStorage.setItem(key, data)
}
export function getLoc(key) {
    return localStorage.getItem(key)
}
export function removeLoc(e='access_token') {
    return localStorage.removeItem(e)
}
export function clearCookieAll() {
	let keys = document.cookie.match(/[^ =;]+(?=\=)/g);
	if (keys) {
		for (var i = keys.length; i--;) {
			document.cookie = keys[i] + '=0;path=/;expires=' + new Date(0).toUTCString();//清除当前域名下的
			document.cookie = keys[i] + '=0;path=/;domain=hd199.com;expires=' + new Date(0).toUTCString();//清除一级域名下的
		}
	}
}

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '/',
    component: () => import( '../views/index.vue')
  },
  {
    path: '/kjds',
    name: 'kjds',
    component: () => import( '../views/kjds.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import( '../views/about.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import( '../views/login.vue')
  }

 
]

const router = new VueRouter({
  routes
})

export default router
